import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setVideoTime } from 'store/reducers/watchReducer';

export const Television = ({ videoSrc, isMobile }) => {


  if (isMobile) {
    return (
      <TelevisionScreen videoSrc={videoSrc} isMobile={isMobile}/>
    );
  } else {
    return (
      <svg width="1186" height="835" viewBox="0 0 1186 835" fill="none">
        <g id="g8">
          <g id="g124">
            <g id="Clip path group">
              <g id="g126">
                <g id="g132">
                  <g id="g192">
                    <g id="Clip path group_2">
                      <mask
                        id="mask0_1_1202"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="772"
                        width="1186"
                        height="63">
                        <g id="clipPath138">
                          <path
                            id="path136"
                            d="M0.582031 772.115H1185.19V834.531H0.582031V772.115Z"
                            fill="white"
                          />
                        </g>
                      </mask>
                      <g mask="url(#mask0_1_1202)">
                        <g id="g190" opacity="0.5">
                          <g id="g188">
                            <g id="Clip path group_3">
                              <mask
                                id="mask1_1_1202"
                                maskUnits="userSpaceOnUse"
                                x="0"
                                y="772"
                                width="1186"
                                height="63">
                                <g id="clipPath142">
                                  <path
                                    id="path140"
                                    d="M1185.19 803.33C1185.19 786.083 920.004 772.115 592.902 772.115C265.784 772.115 0.582031 786.083 0.582031 803.33C0.582031 820.563 265.784 834.531 592.902 834.531C920.004 834.531 1185.19 820.563 1185.19 803.33Z"
                                    fill="white"
                                  />
                                </g>
                              </mask>
                              <g mask="url(#mask1_1_1202)">
                                <g id="g186">
                                  <g id="g184">
                                    <g id="Mask group">
                                      <mask
                                        id="mask2_1_1202"
                                        maskUnits="userSpaceOnUse"
                                        x="-83985"
                                        y="-72846"
                                        width="167113"
                                        height="148270">
                                        <g id="Group">
                                          <g id="g166">
                                            <g id="g164">
                                              <g id="g162">
                                                <g id="g160">
                                                  <path
                                                    id="path158"
                                                    d="M-83984.4 -72845.9H83127.8V75423.3H-83984.4V-72845.9Z"
                                                    fill="url(#paint0_radial_1_1202)"
                                                  />
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </mask>
                                      <g mask="url(#mask2_1_1202)">
                                        <g id="g182">
                                          <g id="g180">
                                            <path
                                              id="path178"
                                              d="M1185.26 803.392C1185.26 786.146 920.071 772.177 592.968 772.177C265.85 772.177 0.648438 786.146 0.648438 803.392C0.648438 820.625 265.85 834.594 592.968 834.594C920.071 834.594 1185.26 820.625 1185.26 803.392Z"
                                              fill="url(#paint1_radial_1_1202)"
                                            />
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g242">
            <g id="g244">
              <g id="g250">
                <g id="g252">
                  <path
                    id="path260"
                    d="M251.947 800.429H211.385L224.54 701.221H281.545L251.947 800.429Z"
                    fill="url(#paint2_linear_1_1202)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="g262">
            <g id="Clip path group_4">
              <g id="g264">
                <g id="g270">
                  <g id="g284">
                    <g id="Clip path group_5">
                      <mask
                        id="mask3_1_1202"
                        maskUnits="userSpaceOnUse"
                        x="237"
                        y="701"
                        width="45"
                        height="100">
                        <g id="clipPath276">
                          <path
                            id="path274"
                            d="M237.696 701.221H281.545V800.429H237.696V701.221Z"
                            fill="white"
                          />
                        </g>
                      </mask>
                      <g mask="url(#mask3_1_1202)">
                        <g id="g282" opacity="0.399994">
                          <g id="g280">
                            <path
                              id="path278"
                              d="M251.949 800.43H237.697L257.431 701.22H281.546L251.949 800.43Z"
                              fill="#6D6D6D"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="g286">
            <g id="g288">
              <g id="g294">
                <g id="g296">
                  <path
                    id="path304"
                    d="M933.825 800.429H974.385L961.232 701.221H904.222L933.825 800.429Z"
                    fill="url(#paint3_linear_1_1202)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="g306">
            <g id="Clip path group_6">
              <g id="g308">
                <g id="g314">
                  <g id="g328">
                    <g id="Clip path group_7">
                      <mask
                        id="mask4_1_1202"
                        maskUnits="userSpaceOnUse"
                        x="904"
                        y="701"
                        width="45"
                        height="100">
                        <g id="clipPath320">
                          <path
                            id="path318"
                            d="M904.223 701.221H948.075V800.429H904.223V701.221Z"
                            fill="white"
                          />
                        </g>
                      </mask>
                      <g mask="url(#mask4_1_1202)">
                        <g id="g326" opacity="0.399994">
                          <g id="g324">
                            <path
                              id="path322"
                              d="M933.825 800.43H948.075L928.343 701.219H904.223L933.825 800.43Z"
                              fill="#6D6D6D"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="g330">
                  <path
                    id="path332"
                    d="M1059.89 692.469C1059.89 708.585 1045.17 721.648 1027 721.648H158.763C140.6 721.648 125.876 708.585 125.876 692.469C125.876 676.356 140.6 663.291 158.763 663.291H1027C1045.17 663.291 1059.89 676.356 1059.89 692.469Z"
                    fill="#772808"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="g334">
            <g id="g336">
              <g id="g342">
                <g id="g344">
                  <path
                    id="path352"
                    d="M88.5986 0.878017C52.2718 0.878017 22.8247 27.0069 22.8247 59.2377V640.882C22.8247 673.113 52.2718 699.242 88.5986 699.242H1097.17C1133.49 699.242 1162.95 673.113 1162.95 640.882V59.2377C1162.95 27.0069 1133.49 0.878017 1097.17 0.878017H88.5986Z"
                    fill="url(#paint4_linear_1_1202)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="g354">

            <g id="g356">
              <g id="g362">
                <g id="g364">
                  <path
                    id="path374"
                    
                    d="M97.9956 17.9523C67.7402 17.9523 43.1279 39.7894 43.1279 66.6355V637.443C43.1279 664.282 67.7402 686.124 97.9956 686.124H1087.77C1118.03 686.124 1142.64 664.282 1142.64 637.443V66.6355C1142.64 39.7894 1118.03 17.9523 1087.77 17.9523H97.9956Z"
                    fill="url(#paint5_linear_1_1202)"
                  />
                </g>
              </g>
            </g>
          </g>
          <g id="g376">
            <g id="g378">
              <g id="g384">
                <g id="g386">
                <foreignObject   x="5%" 
    y="5%" 
    width="90%" 
    height="90%" 
    style={{            zIndex: 1000,
    }}
    >
            <TelevisionScreen videoSrc={videoSrc}/>
          </foreignObject>
                </g>
              </g>
            </g>
          </g>
          <g id="g398">
            <g id="Clip path group_8">
              <g id="g400">
                <g id="g406">
                  <g id="g438">
                    <g id="Clip path group_9">
                      <mask
                        id="mask5_1_1202"
                        maskUnits="userSpaceOnUse"
                        x="55"
                        y="76"
                        width="1077"
                        height="388">
                        <g id="clipPath412">
                          <path
                            id="path410"
                            d="M55.0527 76.0681H1131.55V463.64H55.0527V76.0681Z"
                            fill="white"
                          />
                        </g>
                      </mask>
                      <g mask="url(#mask5_1_1202)">
                        <g id="g436" opacity="0.300003">
                          <g id="g434">
                            <g id="g432">
                              <g id="g430">
                                <g id="g428">
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>

        </g>
        <defs>
          <radialGradient
            id="paint0_radial_1_1202"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(592.939 803.35) scale(593.908 31.1239)">
            <stop stopColor="white" />
            <stop offset="0.323048" stopColor="white" />
            <stop offset="1" stopColor="white" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_1_1202"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(592.953 803.385) scale(593.908 31.1239)">
            <stop stopColor="#666666" />
            <stop offset="0.323048" stopColor="#666666" />
            <stop offset="1" stopColor="#F2F2F2" />
          </radialGradient>
          <linearGradient
            id="paint2_linear_1_1202"
            x1="246.465"
            y1="821.83"
            x2="246.465"
            y2="703.1"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2E302F" />
            <stop offset="1" stopColor="#1C1E1D" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1_1202"
            x1="939.304"
            y1="821.832"
            x2="939.304"
            y2="703.103"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2E302F" />
            <stop offset="1" stopColor="#1C1E1D" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1_1202"
            x1="592.887"
            y1="-82.7738"
            x2="592.887"
            y2="853.731"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#E89356" />
            <stop offset="1" stopColor="#BB5013" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1_1202"
            x1="592.885"
            y1="-14.1287"
            x2="592.885"
            y2="702.883"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#6D6D6D" />
            <stop offset="0.0042735" stopColor="#6D6D6D" />
            <stop offset="1" stopColor="#736f64" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1_1202"
            x1="592.882"
            y1="705.326"
            x2="592.882"
            y2="28.0959"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#4D4F4E" />
            <stop offset="0.995726" stopColor="#969696" />
            <stop offset="1" stopColor="#969696" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1_1202"
            x1="593.299"
            y1="705.326"
            x2="593.299"
            y2="28.0958"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#FBFBFB" />
            <stop offset="0.0042735" stopColor="#FBFBFB" />
            <stop offset="1" stopColor="#969696" />
          </linearGradient>
        </defs>

      </svg>
    );
  }
};


const TelevisionScreen = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const videoTime = useSelector(state => state.videoWatcher.videoTime);
  const modalOpen = useSelector(
    state => state.videoWatcher.modalOpen
  );

  useEffect(() => {
    if (videoRef.current && videoTime !== undefined) {
      videoRef.current.currentTime = videoTime;
    }
  }, [videoTime, videoSrc]);

  useEffect(() => {
    if (videoRef.current) {
      if (modalOpen) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
    }
  }, [modalOpen]);

  const handlePause = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      dispatch(setVideoTime(currentTime));
    }
  };

  useEffect(() => {
    const player = videoRef.current;
    if (player) {
      player.addEventListener('pause', handlePause);
    }
  }, []);


return (
  
  <div className="television">
  <mux-player
    autoPlay
    ref={videoRef}
    accent-color="#f5ba41"
    autoplay
    loop
    playsinline
    style={{
      width: '100%',
      height: '100%',
      flexGrow: 1,
      border: 0
    }}
    poster="https://as1.ftcdn.net/v2/jpg/01/96/04/46/1000_F_196044671_CcSpNsNdU2EjQRypgJyMbTkq2XAo4VC6.jpg"
    src={videoSrc}
    allow="autoplay; fullscreen"
    controls></mux-player>
</div>
)
}