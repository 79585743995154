const initialState = {
  videos: [], 
  filteredVideos: [],
  modalOpen: false,
};

const GET_VIDEOS_FROM_CLOUDINARY = 'GET_VIDEOS_FROM_CLOUDINARY';
const SET_FILTERED_VIDEOS = 'SET_FILTERED_VIDEOS';
const SET_SELECTED_VIDEO = 'SET_SELECTED_VIDEO';
const SET_VIDEO_TIME = 'SET_VIDEO_TIME';
const MODAL_OPEN = 'MODAL_OPEN';

// const UPDATE_VIDEO_BY_ID = 'UPDATE_VIDEO_BY_ID';

export const setVideos = videos => ({
  type: GET_VIDEOS_FROM_CLOUDINARY,
  videos
});

export const setFilteredVideos = filteredVideos => ({
  type: SET_FILTERED_VIDEOS,
  filteredVideos
});

export const setSelectedVideo = selectedVideo => ({
  type: SET_SELECTED_VIDEO,
  selectedVideo
});

export const setVideoTime = videoTime => ({
  type: SET_VIDEO_TIME,
  videoTime
});

export const setModalOpen = modalOpen => ({
  type: MODAL_OPEN,
  modalOpen
});

// export const updateVideoById = (video_id, video_name) => ({
//   type: UPDATE_VIDEO_BY_ID,
//   payload: {
//     video_id,
//     video_name,
//   },
// });



function watchReducer(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS_FROM_CLOUDINARY: {
      return {
        ...state,
        videos: action.videos
      };
    }
    case SET_FILTERED_VIDEOS: {
      return {
        ...state,
        filteredVideos: action.filteredVideos
      };
    }
    case SET_SELECTED_VIDEO: {
      return {
        ...state,
        selectedVideo: action.selectedVideo
      };
    }
    case SET_VIDEO_TIME: {
      return {
        ...state,
        videoTime: action.videoTime
      };
    }

    case MODAL_OPEN: {
      return {
        ...state,
        modalOpen: action.modalOpen
      };
    }
    // case UPDATE_VIDEO_BY_ID: {
    //   const { video_id, video_name } = action.payload;
    //   const updatedVideos = state.videos.map(video =>
    //     video.video_id === video_id ? { ...video, video_name } : video
    //   );

    //   return {
    //     ...state,
    //     videos: updatedVideos,
    //   };
    // }
    default:
      return state;
  }
}

export default watchReducer;
