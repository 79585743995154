import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { UserContext } from 'components';
import Routes from 'config/routes';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

const GOOGLE_ANALYTICS_TAG = 'G-9PZYH8S93W';
const tagManagerArgs = {
  gtmId: 'GTM-T7MB9P27'
};

if (
  window.location.hostname != 'localhost' &&
  window.location.hostname != '127.0.0.1'
) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TAG);
  TagManager.initialize(tagManagerArgs);
}

function App() {
  const [user, setUser] = useState(null);

  return (
    <>
      <UserContext.Provider value={{ user, setUser }}>
        <Routes />
      </UserContext.Provider>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover={false}
      />
    </>
  );
}

export default App;
