import { Layout } from 'antd';
import { UserContext } from 'components';
import VideoStudio from 'components/VideoPlayer/VideoStudio';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVideos } from 'store/reducers/watchReducer';
import { getProjectVideos } from 'utils/hooks';
import styles from './Home.module.css';
import PendingApprovalMessage from './PendingApproval/ProjectPendingApproval';

const Home = ({ project }) => {
  const { user, setUser } = useContext(UserContext);
  const dispatch = useDispatch();
  const videos = useSelector(state => state.videoWatcher.videos);
  const viewportWidth = window.innerWidth;
  const mobileScreen = viewportWidth <= 1000;

  useEffect(() => {
    getVideos();
  }, [project]);

  const getVideos = useCallback(() => {
    getProjectVideos(user, project.id)
      .then(response => {
        dispatch(setVideos(response.data.videos));
      })
      .catch(error => {
        console.error(error);
      });
  }, [user]);

  if (!user) {
    return null;
  }


  if(project.role==="pending") {
    return <PendingApprovalMessage project={project}/>
  }

  return (
    <Layout className={`${styles.content}`}>

        <VideoStudio
          members={project.members}
          showCassetteRow={true}
          videos={videos}
          isMobile={mobileScreen}
          companions={project.companions}
        />
    </Layout>
  );
};

export default Home;
