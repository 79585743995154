import CassetteRow from 'pages/Project/Home/CassettesRow/CassetesRow';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './style.css';
import { Television } from './Television';

const VideoStudio = ({
  members,
  isMobile,
  companions,
  showCassetteRow,
  videos
}) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [narrationData, setNarrationData] = useState([]);
  const [subtitleText, setSubtitleText] = useState([]);

  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);
  const videoTime = useSelector(state => state.videoWatcher.videoTime);

  useEffect(() => {
    const matchingNarrations = narrationData.filter(
      narration =>
        currentTime >= narration.start_time && currentTime <= narration.end_time
    );
    setSubtitleText(matchingNarrations);
  }, [currentTime, narrationData]);

  if(isMobile) {
    return (
    <div className="tv" style={{  flexDirection:  "column"      }}>

     
    <Television
      videoSrc={
        selectedVideo === undefined
          ? ''
          : selectedVideo.url 
      }
      isMobile={isMobile}
    />
        <div className={`cassette-container-mobile`} id="portal-root">
      <CassetteRow videos={videos}   />
    </div>
</div>
)
  }


  else {  
    return (
    
    <div className="tv" style={{  flexDirection: "row"      }}>
        <div className={`cassette-container`} id="portal-root">
          <CassetteRow videos={videos}  mobileScreen={isMobile}  />
        </div>
         
        <Television
          videoSrc={
            selectedVideo === undefined
              ? ''
              : selectedVideo.url 
          }
          isMobile={isMobile}
        />
    </div>
  );

  }

};

export default VideoStudio;
