import { Button, Input, Typography, List, Modal, Card } from 'antd';
import {
  getVideoDetails,
  updateVideo,
  updateScene,
  deleteScene,
  uploadScene
} from 'pages/Project/requests';
import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { TaggedTextInput } from '../VideoNarration/TaggedTextInput';
import notification from 'antd/lib/notification';
import { UserContext } from 'components';
import { EditingScene } from './EditingScene';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateScene } from './CreateScene';

const { Text } = Typography;

const VideoDetailsPanel = ({ selectedVideo, handleModalCancel }) => {
  const [currentName, setCurrentName] = useState('');
  const [description, setDescription] = useState('');
  const [scenes, setScenes] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateSceneModalOpen, setIsCreateSceneModalOpen] = useState(false);
  const [newSceneData, setNewSceneData] = useState(null);

  const [editingScene, setEditingScene] = useState(null);
  const { user } = useContext(UserContext);
  const handleOpenModal = () => setIsCreateSceneModalOpen(true);
  const handleCloseModal = () => {
    setIsCreateSceneModalOpen(false);
  };

  const companions = useSelector(
    state => state.projectManager.project.companions
  );
  const members = useSelector(state => state.projectManager.project.members);
  const tags = useSelector(state => state.projectManager.project.tags);

  const showNotification = (type, message) => {
    notification[type]({ message, placement: 'bottomRight' });
  };

  useEffect(() => {
    const fetchDetails = async () => {
      const details = await getVideoDetails({
        video_id: selectedVideo?.video_id
      });
      setCurrentName(details.data.video_name);
      setDescription(details.data.video_description);
      setScenes(details.data.scenes || []);
    };
    fetchDetails();
  }, [selectedVideo]);

  const handleUpdate = async () => {
    try {
      const updatedVideo = {
        ...selectedVideo,
        video_name: currentName,
        video_description: description,
        scenes
      };
      const response = await updateVideo({
        video_id: selectedVideo?.video_id,
        data: updatedVideo,
        userId: user.id
      });

      if (response.ok) {
        showNotification('success', 'Updated video');
      } else {
        showNotification('error', 'Failed to update video');
      }
    } catch (error) {
      showNotification('error', 'Error occurred: ' + error);
    }
  };

  const handleCreateNewScene = async () => {
    if (!newSceneData) {
      showNotification('success', 'Please fill out all required fields.');
      return;
    }
    try {
      await uploadScene({
        newSceneData,
        video_id: selectedVideo.video_id,
        userId: user.id
      });
      showNotification('success', 'Scene created successfully!');
      handleModalCancel();
      handleCloseModal();
    } catch (error) {
      showNotification('error', 'Error creating scene. Please try again.');
    }
  };

  const handleDeleteScene = async scene => {
    try {
      const response = await deleteScene({
        scene_id: scene.id,
        userId: user.id
      });
      setScenes(prevScenes =>
        prevScenes.filter(prevScene => prevScene.id !== scene.id)
      );

      if (response.ok) {
        showNotification('success', 'Deleted scene');
      } else {
        showNotification('error', 'Failed to update scene');
      }
    } catch (error) {
      showNotification('error', 'Error occurred: ' + error);
    }
  };

  const openSceneModal = scene => {
    setEditingScene(scene);
    setIsModalOpen(true);
  };

  const closeSceneModal = () => {
    setIsModalOpen(false);
    setEditingScene(null);
  };

  const handleSceneChange = (key, value) => {
    setEditingScene(prev => ({ ...prev, [key]: value }));
  };

  const saveSceneChanges = async () => {
    try {
      const updatedScene = await updateScene({
        scene_id: editingScene.id,
        data: editingScene,
        userId: user.id
      });

      setScenes(prevScenes =>
        prevScenes.map(scene =>
          scene.id === updatedScene.id ? updatedScene : scene
        )
      );

      showNotification('success', 'Scene updated successfully');
      closeSceneModal();
    } catch (error) {
      showNotification('error', `Error updating scene: ${error.message}`);
    }
  };

  const handleDateChange = (date, dateString) => {
    handleSceneChange('scene_date', dateString);
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <div style={{ marginBottom: '20px' }}>
        <Text strong>Video Name</Text>
        <Input
          value={currentName}
          placeholder="Select a VHS tape"
          onChange={e => setCurrentName(e.target.value)}
          style={{ marginBottom: '10px', display: 'block', width: '100%' }}
        />
        <TaggedTextInput
          tags={tags}
          members={members}
          companions={companions}
          text={description}
          handleLineTextChange={setDescription}
          index={0}
        />
      </div>
      <>
        <div
          style={{
            maxHeight: '300px',
            overflowY: 'auto',
            marginBottom: '10px'
          }}>
          <List
            grid={{ gutter: 16, column: 2 }}
            dataSource={[...scenes, { isCreateButton: true }]} // Add a "create" placeholder
            renderItem={scene =>
              scene.isCreateButton ? (
                <List.Item>
                  <Button
                    type="dashed"
                    icon={<PlusOutlined />}
                    style={{
                      width: '100%',
                      height: '200px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '16px'
                    }}
                    onClick={handleOpenModal}>
                    Create Scene
                  </Button>
                </List.Item>
              ) : (
                <List.Item>
                  <div style={{ position: 'relative' }}>
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      style={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                        zIndex: 10,
                        color: 'red',
                        background: 'white',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
                      }}
                      onClick={() => handleDeleteScene(scene)}
                    />
                    <Card
                      hoverable
                      cover={
                        <img
                          alt="thumbnail"
                          src={scene.thumbnail}
                          style={{ height: '200px', objectFit: 'cover' }}
                          onClick={() => openSceneModal(scene)}
                        />
                      }>
                      <Card.Meta title={scene.scene_name} />
                    </Card>
                  </div>
                </List.Item>
              )
            }
          />
        </div>

        <Modal
          title="Create New Scene"
          visible={isCreateSceneModalOpen}
          onOk={handleCreateNewScene}
          onCancel={handleCloseModal}
          okText="Save"
          cancelText="Cancel">
          <CreateScene setNewSceneData={setNewSceneData} />
        </Modal>
      </>

      <Button
        type="primary"
        onClick={handleUpdate}
        style={{ marginTop: '20px', width: '100%' }}>
        Submit
      </Button>
      <Modal
        title="Edit Scene Details"
        open={isModalOpen}
        onOk={saveSceneChanges}
        onCancel={closeSceneModal}>
        {editingScene && (
          <EditingScene
            editingScene={editingScene}
            handleSceneChange={handleSceneChange}
            handleDateChange={handleDateChange}
          />
        )}
      </Modal>
    </div>
  );
};

export default VideoDetailsPanel;
