import React, { useState } from 'react';
import { Menu } from 'antd';
import styled from '@emotion/styled';
import Home from './Home';
import Upload from './Upload';
import Edit from './Edit';
import { UserContext } from 'components';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchProject } from 'store/actions/projectActions';
import {  Spin } from 'antd';
import { sendPageView } from 'utils/google_analytics/google_analytics_utils';
import { PAGES } from 'utils/google_analytics/enums/pages';

const StyledMenuContainer = styled.div`
  width: 300;
  text-align: right;
  position: fixed;
  top: 10px;
  right: 10px;

  .ant-menu {
    width: 100%;
  }
`;

const Project = ({ projectId }) => {


  const { user } = useContext(UserContext);
  const [selectedComponent, setSelectedComponent] = useState('watch');

  const dispatch = useDispatch();
  const project = useSelector((state) => state.projectManager.project);
  const [projectPendingApproval, setProjectPendingApproval] = useState(false);

  const isLoading = useSelector((state) => state.projectManager.isLoading);
  const error = useSelector((state) => state.projectManager.error);

  useEffect(() => {
    sendPageView(PAGES.PROJECT)
  }, []);

  useEffect(() => {
    if(user){
      dispatch(fetchProject({ userId: user.id, projectId }));
    }
  }, [dispatch, user, projectId]);

  useEffect(() => {
    setProjectPendingApproval(project?.role==="pending")
  }, [project]);


  const renderComponent = () => {
    if (isLoading) {
      return <Spin size="large" />;
    }
    switch (selectedComponent) {
      case 'watch':
        return <Home project={project}  style={{ marginLeft: 0}}/>;
      case 'upload':
        return <Upload project={project} />;
      case 'edit':
        return <Edit project={project} />;
      default:
        return <Home project={project} style={{ marginLeft: 0}} />;
    }
  };

  return (
    <div>
      {!projectPendingApproval && (

      <StyledMenuContainer>
        <Menu
          mode="horizontal"
          theme="light"
          selectedKeys={[selectedComponent]}
          onClick={({ key }) => setSelectedComponent(key)}
        >
  <>
    <Menu.Item key="watch">Watch</Menu.Item>
    <Menu.Item key="upload">Upload</Menu.Item>
    <Menu.Item key="edit">Edit</Menu.Item>
  </>
        </Menu>
      </StyledMenuContainer>
      )}

      {renderComponent()}
    </div>
  );
};

export default Project;
