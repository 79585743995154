
import ReactGA from 'react-ga4';
const { REACT_APP_ENV } = process.env;

export const sendPageView = (page) => {
    // if(REACT_APP_ENV === 'prod') {
    //     ReactGA.send({ hitType: 'pageview', page });
    //   }
}


export const sendSupplierReferral = (supplier) => {
  // if (REACT_APP_ENV === 'prod') {
  //   ReactGA.event({
  //     category: 'Supplier Referrals',
  //     action: 'Sent',
  //     label: supplier,
  //   });
  // }
};
