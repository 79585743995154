import React, { useState } from 'react';
import { Modal } from 'antd';
import { EditModal as EditOptions } from './EditModal';
import { setModalOpen } from 'store/reducers/watchReducer';
import { useDispatch } from 'react-redux';

const EditButton = ({disabled, video }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = () => {
      setIsModalOpen(true);
      dispatch(setModalOpen(true));
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    dispatch(setModalOpen(false));
  };

  return (
    <div>
      <div>
        <svg
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => {
            if (video) {
              handleClick();
            }
          }}
          style={{
            fill: disabled ? 'gray' : isHovered ? '#e7a83f' : 'black',
            cursor: disabled ? 'not-allowed' : 'pointer',
          }}
          width="33px"
          height="33px"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <title>film</title>
          <path d="M30.335 26.445c-0.128-0.221-0.538-0.289-1.231-0.202-1.275 0.165-2.201-0.251-2.778-1.251-0.437-0.756-0.55-1.516-0.339-2.28s0.836-1.854 1.874-3.272l0.649-0.904c1.928-2.663 2.323-4.979 1.186-6.95-1.157-2.006-4.5-1.837-6.903-0.806-1.944-4.009-6.040-6.78-10.793-6.78-6.628 0-12 5.373-12 12s5.372 12 12 12c6.627 0 12-5.373 12-12 0-1.469-0.277-2.871-0.76-4.171 0.811-0.291 1.781-0.502 2.696-0.536 1.202-0.044 2.29 0.208 2.722 0.956 0.963 1.669 0.498 3.641-1.395 5.913l-0.388 0.466c-2.214 2.657-2.743 4.984-1.591 6.982 0.466 0.806 1.207 1.381 2.225 1.727 1.018 0.345 1.879 0.312 2.585-0.096 0.35-0.202 0.431-0.468 0.241-0.796zM6.5 18.562c-1.381 0-2.5-1.119-2.5-2.5 0-1.38 1.119-2.5 2.5-2.5s2.5 1.119 2.5 2.5c0 1.381-1.119 2.5-2.5 2.5zM12 24.062c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5zM11.020 16.062c0-0.552 0.449-1 1.001-1s1 0.447 1 1c0 0.553-0.448 1-1 1s-1.001-0.447-1.001-1zM12 13.062c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5c0 1.381-1.119 2.5-2.5 2.5zM17.5 18.562c-1.381 0-2.5-1.119-2.5-2.5 0-1.38 1.119-2.5 2.5-2.5s2.5 1.119 2.5 2.5c0 1.381-1.119 2.5-2.5 2.5z"></path>
        </svg>
      </div>
      <Modal
        title={`Editing`}
        open={isModalOpen}
        width="800px"
        height="600px"
        onCancel={handleModalCancel}
        footer={null}>
        <EditOptions selectedVideo={video} handleModalCancel={handleModalCancel} />
      </Modal>
    </div>
  );
};

export default EditButton;
