import { Input, Space, Spin, DatePicker } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getSceneThumbnail } from 'pages/Project/requests';
import { notifyError } from 'utils/notifications';

const isValidDate = date => {
  return dayjs(date).isValid();
};

export const CreateScene = ({ setNewSceneData }) => {
  const [newSceneName, setNewSceneName] = useState('');
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [blob, setBlob] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sceneDate, setSceneDate] = useState(dayjs());

  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);
  const videoTime = useSelector(state => state.videoWatcher.videoTime);

  const handleFieldChange = () => {
    setNewSceneData({
      scene_name: newSceneName,
      scene_date: sceneDate,
      video_time: videoTime,
      image: blob
    });
  };

  const extractThumbnail = async () => {
    setIsLoading(true);
    try {
      const response = await getSceneThumbnail({ 
        videoID: selectedVideo.video_id, 
        videoTime 
      });
      
      if (response.data && response.data.thumbnail) {
        setThumbnailSrc(`data:image/jpeg;base64,${response.data.thumbnail}`);
        setBlob(response.data.thumbnail)
      } 
    } catch (error) {
      notifyError('Failed to extract thumbnail');
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    extractThumbnail();
  }, [videoTime]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 340
        }}>
        {isLoading ? (
          <Spin size="large" />
        ) : (
          thumbnailSrc && (
            <img
              src={thumbnailSrc}
              alt="Thumbnail"
              style={{ width: '80%', display: 'block', margin: 'auto' }}
            />
          )
        )}
      </div>
      <Input
        placeholder="Scene Name"
        value={newSceneName}
        onChange={e => {
          setNewSceneName(e.target.value);
          handleFieldChange();
        }}
      />
      <DatePicker
        value={isValidDate(sceneDate) ? sceneDate : dayjs()}
        onChange={date => {
          setSceneDate(date.format('YYYY-MM-DD'));
          handleFieldChange();
        }}
        format="YYYY-MM-DD"
        style={{ width: '100%' }}
      />
    </Space>
  );
};
