import React, { useEffect, useState, useContext } from 'react';
import styled from '@emotion/styled';
import ScenesPopup from './ScenesPopup';
import { updateVideo } from 'pages/Project/requests';
import { useSelector, useDispatch } from 'react-redux';
import { useDrag, DragPreviewImage } from 'react-dnd';
import { boxImage } from './BoxImage.js';
import { UserContext } from 'components';
import { setSelectedVideo } from 'store/reducers/watchReducer';

const StyledSvg = styled.svg`
  transition: transform 0.5s ease;
  cursor: pointer;
  position: relative;
  margin-top: -14px;
  transform: translateX(${({ isSelected }) => (isSelected ? '15px' : '0')});

  &:hover {
    transform: translateX(${({ isSelected }) => (isSelected ? '15px' : '0')});
  }
`;

const Container = styled.div`
  display: inline-block;
  position: relative;
`;

const Cassette = ({ video_details,playInsertSound,playEjectSound, video_id, name, onClick, scenes }) => {
  const [isCassetteHovered, setIsCassetteHovered] = useState(false);
  const [currentName, setCurrentName] = useState(name);
  const selectedVideo = useSelector(state => state.videoWatcher.selectedVideo);
  const [isSelected, setIsSelected] = useState(false);
  const { user } = useContext(UserContext);

  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentName(name);
  }, [name]);

  useEffect(() => {
    if (isSelected) {
      if (selectedVideo.video_id !== Number(video_id)) {
        setIsSelected(false);
      }
    }
  }, [selectedVideo]);

  const handleCassetteHover = () => {
    setIsCassetteHovered(true);
  };

  const handleNameUpdate = newName => {
    if (newName !== currentName) {
      const updatedVideo = {
        video_name: newName
      };
      updateVideo({ video_id, data: updatedVideo, userId: user.id });
      setCurrentName(newName);
    }
  };

  const handleMouseLeave = () => {
    setIsCassetteHovered(false);
  };

  const [{}, drag, preview] = useDrag(() => ({
    type: 'Cassette',
    item: { video_id, name, video_details },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult && dropResult.trashCanDropped) {
        console.log(
          'Cassette dropped on trash can with tapeId:',
          dropResult.tapeId
        );
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  }));

  return (
    <div>
      <DragPreviewImage connect={preview} src={boxImage} />
      <Container
        onMouseEnter={handleCassetteHover}
        onMouseLeave={handleMouseLeave}>
        <div>
          <StyledSvg
            isSelected={isSelected}
            style={{ marginTop: -6 }}
            key={video_id}
            className='vhs-tape'
            onClick={e => {
              if (!isSelected) {
                playInsertSound();
                onClick(e);
              } else {
                playEjectSound();
                dispatch(setSelectedVideo(undefined));
              }
              setIsSelected(!isSelected);
            }}
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            height="58.5"
            width="320">
            <path
              d="M0.604187 52.3038L315.029 52.3038V0.401451L0.604187 0.401451V52.3038Z"
              fill="#020200"
            />
            <path
              d="M5.69769 50.8893L309.63 50.8893C311.936 50.8893 313.806 49.02 313.806 46.7142V5.98719C313.806 3.68133 311.936 1.81205 309.63 1.81205L5.69769 1.81205C3.39182 1.81205 1.52255 3.68133 1.52255 5.98719V46.7142C1.52255 49.02 3.39182 50.8893 5.69769 50.8893Z"
              fill="#333333"
            />
            <path
              d="M309.788 50.8893H296.421C295.919 50.8893 295.438 50.6898 295.083 50.3347C294.727 49.9796 294.528 49.498 294.528 48.9958V3.69796C294.528 3.19578 294.727 2.71416 295.083 2.35906C295.438 2.00397 295.919 1.80448 296.421 1.80448L310.155 1.80448C311.123 1.80448 312.052 2.1891 312.736 2.87373C313.421 3.55835 313.806 4.4869 313.806 5.45511L313.806 46.8732C313.806 47.4008 313.702 47.9231 313.5 48.4105C313.298 48.8979 313.002 49.3407 312.629 49.7137C312.255 50.0866 311.813 50.3824 311.325 50.5841C310.838 50.7858 310.315 50.8895 309.788 50.8893Z"
              fill="#4E4E4E"
            />
            <path
              d="M303.672 50.8914H290.305C289.803 50.8914 289.322 50.6919 288.967 50.3368C288.612 49.9817 288.412 49.5001 288.412 48.9979V3.70004C288.412 3.19785 288.612 2.71624 288.967 2.36114C289.322 2.00604 289.803 1.80655 290.305 1.80655L304.039 1.80655C305.007 1.80655 305.936 2.19117 306.62 2.8758C307.305 3.56043 307.69 4.48898 307.69 5.45719V46.8734C307.69 47.4011 307.586 47.9237 307.384 48.4113C307.182 48.8989 306.886 49.3419 306.513 49.7151C306.14 50.0882 305.697 50.3842 305.21 50.586C304.722 50.7878 304.199 50.8916 303.672 50.8914Z"
              fill="#333333"
            />
            <path
              d="M5.17121 1.81201L18.9028 1.81201C19.4049 1.81201 19.8866 2.01151 20.2417 2.3666C20.5968 2.7217 20.7962 3.20331 20.7962 3.7055L20.7962 49.009C20.7962 49.5112 20.5968 49.9928 20.2417 50.3479C19.8866 50.703 19.4049 50.9025 18.9028 50.9025L5.53855 50.9025C4.47342 50.9025 3.45191 50.4794 2.69875 49.7262C1.94559 48.973 1.52246 47.9516 1.52246 46.8864L1.52246 5.46266C1.52246 4.49445 1.90708 3.56591 2.59171 2.88128C3.27634 2.19665 4.20489 1.81201 5.1731 1.81201H5.17121Z"
              fill="#4E4E4E"
            />
            <path
              d="M11.2893 1.81189L25.0208 1.81189C25.523 1.81189 26.0046 2.01139 26.3597 2.36648C26.7148 2.72158 26.9143 3.20319 26.9143 3.70537L26.9143 49.0089C26.9143 49.5111 26.7148 49.9927 26.3597 50.3478C26.0046 50.7029 25.523 50.9024 25.0208 50.9024L11.6566 50.9024C10.5915 50.9024 9.56995 50.4792 8.81679 49.7261C8.06363 48.9729 7.6405 47.9514 7.6405 46.8863L7.6405 5.46253C7.6405 4.49465 8.02487 3.56638 8.70908 2.88181C9.3933 2.19723 10.3214 1.81239 11.2893 1.81189Z"
              fill="#333333"
            />
            <path
              opacity="0.7"
              d="M313.834 28.2073L1.52261 28.2073"
              stroke="black"
              strokeWidth="0.5"
              strokeMiterlimit="10"
            />
            <path
              d="M264.59 28.2094L280.495 28.2094V6.67096L264.59 6.67096V28.2094Z"
              fill="black"
            />
            <path
              d="M266.508 21.4985L278.547 21.4985C278.763 21.4985 278.937 21.3239 278.937 21.1085V5.64628C278.937 5.43086 278.763 5.25622 278.547 5.25622L266.508 5.25622C266.293 5.25622 266.118 5.43086 266.118 5.64628V21.1085C266.118 21.3239 266.293 21.4985 266.508 21.4985Z"
              fill="#333333"
            />
            <path
              d="M23.0837 47.3597C22.1559 47.3597 21.4023 46.4905 21.4023 45.4188V10.8135C21.4023 9.74179 22.1597 8.87268 23.0837 8.87268L257.687 8.87268C258.614 8.87268 259.368 9.74179 259.368 10.8135V45.4169C259.368 46.4887 258.611 47.3578 257.687 47.3578"
              fill="#F2EFDE"
            />
            <path
              opacity="0.13"
              d="M248.657 35.0012L66.4845 35.0012"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              opacity="0.13"
              d="M248.656 20.5253L66.4843 20.5253"
              stroke="black"
              strokeMiterlimit="10"
            />
            <path
              d="M22.398 47.3579L47.3219 47.3579C47.872 47.3579 48.3179 46.912 48.3179 46.3619L48.3179 9.8688C48.3179 9.31874 47.872 8.87283 47.3219 8.87283L22.398 8.87283C21.8479 8.87283 21.402 9.31874 21.402 9.8688L21.402 46.3619C21.402 46.912 21.8479 47.3579 22.398 47.3579Z"
              fill="#55AC90"
            />
            <path
              d="M50.7929 8.87274L60.7961 8.87274V9.01665L60.7961 47.3578H50.7929L50.7929 9.01665V8.87274Z"
              fill="#952D40"
            />
            <path
              d="M40.7916 8.87268L50.793 8.87268V9.01659L50.793 47.3578H40.7916L40.7916 9.01659V8.87268Z"
              fill="#CE4C29"
            />
            <path
              d="M30.792 8.8728L40.7915 8.8728V9.01671L40.7915 47.3579H30.792L30.792 9.01671V8.8728Z"
              fill="#E7A73F"
            />
            <path
              d="M24.9184 8.87274L30.792 8.87274V9.01665L30.792 47.3578H24.9184V47.1817L24.9184 8.87274Z"
              fill="#55AC90"
            />
            <text
              transform="translate(279.913 38.1115)"
              fill="#F2EFDE"
              fillOpacity="0.21"
              xmlSpace="preserve"
              style={{ whiteSpace: 'pre' }}
              fontFamily="Inter"
              fontSize="5.5"
              fontWeight="bold"
              letterSpacing="0em">
              <tspan x="0" y="5.5">
                VHS 1993
              </tspan>
            </text>
            <text
              transform="translate(66 21)"
              fill="black"
              xmlSpace="preserve"
              style={{ whiteSpace: 'pre' }}
              fontFamily="Inter"
              fontSize="13"
              letterSpacing="0em">
              <tspan x="0" y="9.63636">
                {currentName.length > 29
                  ? `${currentName.substring(0, 29)}...`
                  : currentName}
              </tspan>
            </text>
            <text
              transform="translate(276.721 31.8229)"
              fill="#F2EFDE"
              fillOpacity="0.21"
              xmlSpace="preserve"
              style={{ whiteSpace: 'pre' }}
              fontFamily="Inter"
              fontSize="5.5"
              fontWeight="bold"
              letterSpacing="0em">
              <tspan x="0" y="5.5">
                T -MPG HQ
              </tspan>
            </text>
            <text
              transform="matrix(0 1 -1 0 59.2822 13.4901)"
              fill="#55AC90"
              stroke="#55AC90"
              strokeWidth="0.3"
              strokeMiterlimit="10"
              xmlSpace="preserve"
              style={{ whiteSpace: 'pre' }}
              fontFamily="Inter"
              fontSize="6"
              letterSpacing="0em">
              <tspan x="0" y="5.68182">
                SHOEBOX
              </tspan>
            </text>
          </StyledSvg>
        </div>
        {(isSelected || (!selectedVideo && isCassetteHovered)) &&
          scenes &&
          scenes.length > 0 && (
            <ScenesPopup
              video_id={video_id}
              name={currentName}
              scenes={scenes}
              selectedVideo={selectedVideo}
              handleNameUpdate={handleNameUpdate}
              onMouseLeave={handleMouseLeave}
            />
          )}
      </Container>
    </div>
  );
};

export default Cassette;
