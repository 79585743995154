import React from 'react';
import { Card, Typography, Space } from 'antd';
import { ReactComponent as Tape } from  '../../../Start/images/tape.svg'

const { Title, Text } = Typography;


const PendingApprovalMessage = ({ project }) => {
  if (project.role === "pending") {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginRight: '5px', marginLeft: '5px' }}>
        <Card
          style={{
            maxWidth: 600,
            textAlign: 'center',
            borderRadius: 8,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Space direction="vertical" size="middle">
            <Title level={3}>Invitation Pending Approval</Title>
            <Text>
              You have been invited to <Text strong>{project?.title}</Text> by{' '}
              <Text strong>{project?.inviter}</Text>.
            </Text>
            <Text>We're waiting for them to approve you.</Text>
            <Text>Don't worry, we've let them know. We'll notify you once they approve you.</Text>
            <Tape/>
          </Space>
        </Card>
      </div>
    );
  }
  return null;
};

export default PendingApprovalMessage;
