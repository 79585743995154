import VideoDetailsPanel from 'pages/Project/Home/OptionsPanel/VideoDetails/Index';
import React from 'react';


export const EditModal = ({ selectedVideo, handleModalCancel}) => {
  return (
    <div>
      <div>
        <VideoDetailsPanel selectedVideo={selectedVideo} handleModalCancel={handleModalCancel}/>
      </div>
    </div>
  );
};
